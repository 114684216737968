.react-multiple-carousel__arrow{
z-index: 5;
position:absolute;

}   

.react-multi-carousel-list{
position:static;

 /* margin-left: 10rem ;
 margin-right: 10rem  */
}

.video {
    z-index: 0;
    position: absolute;
    /* margin-left: 1446px; */
    left: 75%;
 
    margin-right: 0%;
    float: right;
    top: 0%;
 
    /* display: flex;
    justify-content: end; */
    width: 25vw;
    background: rgba(255, 255, 255, 0.4);
   
  }