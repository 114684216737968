

  label {
   
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
   
  }
  label input {
    position: absolute;
    left: -9999px;
  }
  label input:checked + .swal2-label {
    /* background-color: rgb(0,0,0); */
    background-color:  rgb(0,0,0);;
    border:1px;
    color: white;
    border-style: solid;
    border-color: rgb(0,0,0);
  }
 
   .swal2-label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 50%;
    width:3rem;
    height:3rem;
    border:1px;
    transition: 0.25s ease;
    border-style: solid;
  }


/* .swal2-radio{
    margin:0;
} */

.swal2-checkbox label, .swal2-radio label {
    margin: 0 10px;

} 
 
  @media only screen and (max-width:500px) {

  label .swal2-label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 50%;
    width:2rem;
    height:2rem;
    font-size: x-small;
    border:1px;
    transition: 0.25s ease;
    border-style: solid;
  }


  .swal2-checkbox label, .swal2-radio label {
    margin: 0 5px;

}

.swal2-title {
  font-size: medium;
}


.swal2-actions {
  font-size: small;
}

.swal2-popup {
  width: 300px;
}

}


/* @media only screen and (max-width:750px) {
  label .swal2-label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 50%;
    width:3rem;
    height:3rem;
    font-size: x-small;
    border:1px;
    transition: 0.25s ease;
    border-style: solid;
  }


  .swal2-checkbox label, .swal2-radio label {
    margin: 0 5px;

}

.swal2-title {
  font-size: large;
}


.swal2-actions {
  font-size: medium;
}
} */