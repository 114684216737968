
#twitter-heart {
  position: absolute;
  margin: 10px;
  
  background-position: 0px;
  
  border-radius: 50%;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  top: -9px;
  left: -9px;
  pointer-events: none;
}

#toggle:checked + #twitter-heart {
  -webkit-animation: heart-animate 0.5s steps(28) forwards;
          animation: heart-animate 0.5s steps(28) forwards;
          background-color: rgb(205, 205, 205) ; border-radius: 100% ;  height: 32px;  width: 32px; top: -10px; left: -10px;
         
        
}


@keyframes heart-animate {

  0% {background-color: #000000 ; border-radius: 100%};
  90% {background-color: #000000; border-radius: 100%};
 100% {background-color: #000000; border-radius: 100%};

  0%   { transform: scale(1); fill: #000000; stroke: none; }
  90%   { transform: scale(1.3); fill: #000000;stroke: none;}
  100% { transform: scale(1); fill: #000000; stroke: none;}
 
}


@media only screen and (max-width:425px) {
  #twitter-heart {
    position: absolute;
    margin: 10px;
    background-position: 0px;
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
    top: -8px;
    left: -8px;
    pointer-events: none;
  }


  #toggle:checked + #twitter-heart {
    -webkit-animation: heart-animate 0.5s steps(28) forwards;
            animation: heart-animate 0.5s steps(28) forwards;
            background-color: rgb(190, 225, 254) ; border-radius: 100% ;   top: -8.5px; left: -8.5px; 
          
  }
  

  @keyframes heart-animate {
 
    0%   { transform: scale(1); background-color: rgb(182,220,251);stroke: none; border-radius: 50%; height: 27px;
      width: 27px;}
    90%   { transform: scale(1.3); background-color: rgb(182,220,251);stroke: none; border-radius: 50%; height: 27px;
      width: 27px;}
    100% { transform: scale(1); background-color: rgb(182,220,251); stroke: none; border-radius: 50%; height: 27px;
      width: 27px;}
   
  }

  @keyframes heart-animate {
   
    0%   { transform: scale(1); fill: #000000;stroke: none;  height: 25px;
      width: 25px;}
    90%   { transform: scale(1.3); fill: #000000;stroke: none; height: 25px;
      width: 25px;}
    100% { transform: scale(1); fill: #000000; stroke: none; height: 25px;
      width: 25px;}
   
  }
}
