@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap');
/* @import url('https://fonts.cdnfonts.com/css/silent-caroline'); */
/* @import url('https://fonts.cdnfonts.com/css/mathilde'); */

@font-face{font-family:silent caroline;font-style:normal;font-weight:600;src:local('Silent Caroline'),url('./Silent-Caroline.otf') format('woff')}


@layer utilities {
  @layer responsive {
   
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; 
      scrollbar-width: none; 
    }
  }
}


/* body {
  -ms-overflow-style: none;
}
body::-webkit-scrollbar {
  display: none;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Lobster";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("images/bg2.jpg");
  background-size:  1920px 1080px; */
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.orderConfirmed {
  font-family: Lobster;
}

.accord {
  box-shadow: none;
}

body.swal2-toast-shown .swal2-container.swal2-center{
  z-index: 10000;
};



/* body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
} */

/* .swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgba(0,0,0,.95)!important;
  
} */


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.style-4::-webkit-scrollbar-track
{
  border-radius: 10px;
	background-color: #F5F5F5;
}

.style-4::-webkit-scrollbar
{
	width: 4px;
height: 4px;
	background-color: #F5F5F5;
}

.style-4::-webkit-scrollbar-thumb
{
  border-radius: 15px;
	background-color: rgb(53, 56, 58);
	
}
    
.bg-gradient{
  background-color: #ffffff;
  /* background: linear-gradient(49deg, rgba(227,242,255,1) 0%, rgba(249,247,240,1) 87%);  */
}

.bg-primary {
  --bg-opacity: 1;
  background-color: rgb(0, 0, 0) ;
}

.border-primary{
  border-color:rgb(0, 0, 0) ;
}

.text-primary {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

button:hover {
  --bg-opacity: 1;
  background-color: rgb(55, 55, 55) ;
} 


.bg-secondary {
  --bg-opacity: 1;
  background-color: #9d9e9e;
}

.ternary {
  --bg-opacity: 1;
  background-color: #474b4f;
}

.svg {
  filter: drop-shadow(2px 4px 2px rgb(0 0 0 / 0.4));
}



.arrow {
  position: absolute;
  top: 50%;
}

.right {
  right: 0;
}

.left {
  left: 0;
}



/* .bg-brand {
  --bg-opacity: 1;
  background-color: rgb(0, 53, 96);
}
.brand {
  opacity: 1;
  color: rgb(0, 53, 96);
} */

.text-green {
  text-decoration: none;
  color: rgb(64, 142, 36);
}

.bg-green {
  --bg-opacity: 1;
  background-color: rgb(64, 142, 36);
}

.text-orange {
  text-decoration: none;
  color: rgb(250, 106, 45);
}

.bg-orange {
  --bg-opacity: 1;
  background-color: rgb(250, 106, 45);
}

.bg-yellow {
  --bg-opacity: 1;
  background-color: rgb(245, 217, 0);
}

.Border{
  box-shadow: none;
}



.App {
  font-family: sans-serif;
  text-align: center;
}


body {
  overflow-x: hidden;
}
main {
  min-height: 80vh;
}

/* h1{
  font-size: 1.8rem;
  padding:1rem 0;
}

h2{
  font-size: 1.4rem;
  padding:0.5rem 0;
}

h3{
    padding:1rem 0;
}

.rating span{
  margin:0.1rem;
} */



.loading {
  filter: blur(2px);
  clip-path: inset(0);
}








/* .zoom-hover-translate {
  display: inline-block;
  position: relative;
} */

/* .zoom-hover-translate::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  
  background-color: rgb(0, 53, 96);
  transition: width 0.25s ease-out;
}
.zoom-hover-translate:hover::before {
  width: 100%;
  content: "Zoom";
}

a.zoom-hover-translate {
  text-decoration: none;
  color: inherit;
}

.zoom-hover-translate {
  display: inline-block;
  position: relative;
} */  
a.underline-hover-effect {
  text-decoration: none;
  color: inherit;
}

.underline-hover-effect {
 width: fit-content;
  position: relative;
}

.underline-hover-effect::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: rgb(0, 0, 0);
  transition: width 0.25s ease-out;
}
.underline-hover-effect:hover::before {
  width: 100%;
}





.box--gradient.silver {
  background: linear-gradient(to bottom, #bcc6cc, #eee, #bcc6cc);
}


.item {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.item .imgs {
  display:block;
}
.item.promoted:after {
  content: attr(data-awards) ' Awards';
  position: absolute;
  background: #C55;
  color: white;
  width: 100%;
  line-height: 1em;
  text-align: center;
  padding: 0.5em 0;
  box-sizing: border-box;
  top: 0;
  right: 0;
  transform: rotate(45deg) translate(30%,-25%);
  transform-origin: 50% 50%;
}

.ellipsis {
  white-space: nowrap;
  overflow :inherit;
  text-overflow: ellipsis;
}


.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    rgb(0,53,96) 0%,
    rgb(0,53,96) 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
     
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}



.PhoneInputInput{
  background-color: rgb(243 244 246) ;
  height: 20px;
}

.PhoneInputInput:focus, .PhoneInputInput:focus{
  outline: none;
}



.perimeter {
  
  max-width: 1024px;
}

.image {
  flex: 0 0 100%;
}



.image, .image * {
        all: revert;
    }

@media (min-width: 415px) {
  .perimeter {
    flex-direction: row;
  }
  
  .image {
    flex: 0 0 50%;
  }
}

@media (min-width: 800px) {
  .image {
    flex: 0 0 33.5%;
  }
}


.img{
  
    max-width: 100%;

}





.range {
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
  border: none;
  padding: 0;
  background: none;
}

.range::-webkit-slider-runnable-track {
  background-color: #d7dbdd;
  height: 6px;
  border-radius: 3px;
  border: 1px solid transparent;
}

.range[disabled]::-webkit-slider-runnable-track {
  border: 1px solid #d7dbdd;
  background-color: rgb(182, 182, 182);
  opacity: 0.4;
}

.range::-moz-range-track {
  background-color: #d7dbdd;
  height: 6px;
  border-radius: 3px;
  border: none;
}

.range::-ms-track {
  color: transparent;
  border: none;
  background: none;
  height: 6px;
}

.range::-ms-fill-lower { 
  background-color: #555555;
  border-radius: 3px;
}

.range::-ms-fill-upper { 
  background-color: #d7dbdd;
  border-radius: 3px;
}

.range::-ms-tooltip { display: none; /* display and visibility only */ }

.range::-moz-range-thumb {
  border-radius: 20px;
  height: 18px;
  width: 18px;
  border: none;
  background: none;
  background-color: #606670;
}

.range:active::-moz-range-thumb {
  outline: none;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  border-radius: 100%;
  background-color: #606670;
  height: 18px;
  width: 18px;
  margin-top: -7px;
}

.bookmarkRibbon {
  
  background: rgba(0, 0, 0, 0.9);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% calc(100% - 20px), 0% 100%, 0% 0%);
 
}



.font-style {
  font-family:'Poppins', sans-serif; 
}


/* .font-myunde {
  font-family: 'Mathilde', sans-serif;
  
} */



.marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  /* width: 100%; */
 font-family: 'Silent Caroline', sans-serif;
 font-size: x-large;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 50s linear infinite;
}

.marquee2 span {
  animation-delay: 25s;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}






