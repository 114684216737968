.stock-images .image {
  opacity: 0.9;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  z-index: -200;
 
}
.stock-images .image:hover {
  opacity: 1;
}

.stock-images [type=radio] + label:before,
.stock-images [type=radio] + label:after {
  display: none;
}
.stock-images [type=radio] + label {
  width: 100%;
  height: 160px;
  padding: 0;
}
.stock-images [type=radio]:not(:checked) + label .image {
  border: 2px solid rgb(209, 209, 209);
}
.stock-images [type=radio]:checked + label .image {
  border: 2px solid rgb(0, 180, 171);
  opacity: 1;
  content: url(./svg.svg);
  z-index: 1000;

}


.stock-sizes .size {
  opacity: 0.9;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  z-index: -1000;
}

.stock-sizes .size:hover {
  opacity: 1;
}

.stock-sizes [type=radio] + .label1:before,
.stock-sizes [type=radio] + .label1:after {
  display: none;
}
.stock-sizes [type=radio] + .label1 {
  width: 100%;
  height: 160px;
  padding: 0;

}
.stock-sizes [type=radio] + .label1 :hover{
  border-color: black;
  
}
.stock-sizes [type=radio]:not(:checked) + .label1 .size {
  border: 1px solid rgb(204, 204, 204);
}
.stock-sizes [type=radio]:checked + .label1 .size {
  border: 2px solid rgb(255, 255, 255);
  opacity: 1;
  background-color: rgb(0, 53, 96);
  /* background-image: linear-gradient(rgb(0, 53, 96), rgb(149, 159, 237)); */
  color:white
}

.accord{
  box-shadow: none;
}