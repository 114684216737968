
 .wish-sizes .wish {
    opacity: 0.9;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin: 3px;
   
   
  } 
  
  .wishinput{
    display: none;
  }
  .wish-sizes .wish:hover {
    opacity: 1;
  }
  
  .wish-sizes [type=radio] + .wishlabel:before,
  .wish-sizes [type=radio] + .wishlabel:after {
    display: none;
  }
  .wish-sizes [type=radio] + .wishlabel {
    /* width: 100%;
    height: 160px; */
    padding: 0;
  }
  .wish-sizes [type=radio]:not(:checked) + .wishlabel .wish {
    border: 1px solid rgb(204, 204, 204);
  
  }
  .wish-sizes [type=radio]:checked + .wishlabel .wish {
    border: 1px solid #000000;
    opacity: 1;
    background-color: #000000;
    /* background-image: linear-gradient(#000000, rgb(149, 159, 237)); */
    color:white
  }

  @media only screen and (max-width:500px) {

    
 .wish-sizes .wish {
  opacity: 0.9;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  margin: 3px;
 
 
} 

    .wish-sizes [type=radio]:not(:checked) + .wishlabel .wish {
      border: 1px solid rgb(204, 204, 204);
      font-size: x-small;
    }
    .wish-sizes [type=radio]:checked + .wishlabel .wish {
      border: 1px solid #000000;
      background-image: linear-gradient(#000000, rgb(149, 159, 237));
      opacity: 1;
      font-size: x-small;
    }

  }
