.reviewInput{
    border:1px;
    width: 350px;
    border:ridge;
    margin-top: 10px;

}
.reviewInput:focus{
    outline: none;
}

.reviewImage{

    border:1px;
    width: 350px;
    border:ridge;
    margin-top: 10px;

}


.reviewImage:focus{
    outline: none;
}

@media only screen and (max-width:450px) {
   
    .reviewInput{
        border:1px;
        width: 230px;
        border:ridge;
        margin-top: 10px;
    
    }
    .reviewImage{

        border:1px;
        width: 230px;
        border:ridge;
        margin-top: 10px;
    
    }
    

}