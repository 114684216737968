/* .bg{
    background-image: url('../images/website\ background.jpg');
    background-size: 100%;
    background-repeat:   no-repeat;   
    background-attachment: fixed;   
} */

.react-multiple-carousel__arrow{
    z-index: 5;
    position:absolute;
    
    }   
    
    .react-multi-carousel-list{
    position:static;
    
     /* margin-left: 10rem ;
     margin-right: 10rem  */
    }
    

